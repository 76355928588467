/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import SignaturePad from 'signature_pad';
import '../css/main.scss';

// import $ from 'jquery';
const $ = require('jquery');
require('bootstrap');


$(document).ready(function(){

    if($('#signature-pad').length){
        loadSignature();
    }
})

function loadSignature()
{
    var pad = $('#signature-pad');
    var canvas = pad[0];

    var signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgba(255,255,255,0)',
        penColor: 'rgb(0,0,0)'
    })

    function resizeCanvas() {
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    pad.hover(function(){
        $('#temporary_signature,#declaration_signature').val(signaturePad.toDataURL("image/svg+xml"));
    })
}